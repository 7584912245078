import React from 'react';
import static1 from '../../assets/AdReel/static1.png';
import static2 from '../../assets/AdReel/static2.png';
import line_vector from '../../assets/AdReel/line_vector.svg';
import line_vector_mobile from '../../assets/AdReel/line_vector_mobile.svg';

const Preview = ({ windowWidth }) => {

    const buttonStyles = {
        height: '50px',
        width: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: '#B3DDE2 ',
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '110%',
        letterSpacing: '-1.28px',
        flexShrink: 0
    };

    const divStyle = {
        backgroundImage: windowWidth > 768 ? `url(${line_vector})` : `url(${line_vector_mobile})`,
        backgroundSize: windowWidth > 768 ? '100vw' : 'auto',
        // width: '100%',
        backgroundRepeat: 'no-repeat',
        zIndex: 1
    };

    return (
        <>
            <div className='container'>
                <p className='preview-title'>The AdReels Ads Experience</p>
                <p className='preview-description'>A streamlined and interactive journey designed to captivate users and drive conversions</p>
            </div>
            <div style={divStyle}>
                <div className='preview-container container'>
                    <div className='preview-images-container'>
                        <video
                            controls={false}
                            loop={true}
                            autoPlay={true}
                            muted
                            playsInline
                            className='preview-image1'
                            style={{
                                borderRadius: windowWidth > 768 ? '25px' : '18px'
                            }}
                        >
                            <source src="https://creatives-genuin-qa.s3.amazonaws.com/asset-creative/screen1adreels.mp4" type="video/mp4" />
                        </video>
                        <video
                            controls={false}
                            loop={true}
                            autoPlay={true}
                            muted
                            playsInline
                            className='preview-image2'
                            style={{
                                borderRadius: windowWidth > 768 ? '25px' : '18px'
                            }}
                        >
                            <source src="https://creatives-genuin-qa.s3.amazonaws.com/asset-creative/screen2adreels.mp4" type="video/mp4" />
                        </video>

                        <img
                            src={static2}
                            alt="Your Image"
                            className='preview-image3'
                        />
                    </div>




                    {windowWidth > 768 ? (
                        <>
                            <div className="containerrr" style={{
                                margin: '0px 80px'
                            }}>
                                <div className="left-div-2">
                                    <span
                                        style={buttonStyles}>
                                        1
                                    </span>
                                </div>
                                <div className="dotted-line"></div>
                                <div className="center-div-2">
                                    <span
                                        style={buttonStyles}>
                                        2
                                    </span>
                                </div>
                                <div className="dotted-line"></div>
                                <div className="right-div-2">
                                    <span
                                        style={buttonStyles}>
                                        3
                                    </span>
                                </div>
                            </div>

                            <div className="containerrr" style={{
                                margin: '10px 0px'
                            }}>
                                <div className="left-div">
                                    <span className='preview-button-text'>
                                        Your Interactive Ad Lives on Any Website
                                    </span>
                                </div>
                                <div className="center-div">
                                    <span className='preview-button-text'>
                                        Your Audience Can Swipe, Engage & Tap on the Vertical Video Playlist
                                    </span>
                                </div>
                                <div className="right-div">
                                    <span className='preview-button-text'>
                                        Video Content Drives to Your Landing Page
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="containerrr" style={{
                                margin: '100px 0px'
                            }} >
                                <div className="left-div">
                                    <span
                                        style={buttonStyles}>
                                        1
                                    </span>
                                    <span className='preview-button-text'>
                                        Your Interactive Ad Lives on Any Website
                                    </span>
                                </div>
                                <div className="vertical-line"></div>
                                <div className="center-div">
                                    <span
                                        style={buttonStyles}>
                                        2
                                    </span>
                                    <span className='preview-button-text'>
                                        Your Audience Can Swipe, Engage & Tap on the Vertical Video Playlist
                                    </span>
                                </div>
                                <div className="vertical-line"></div>

                                <div className="right-div">
                                    <span
                                        style={buttonStyles}>
                                        3
                                    </span>
                                    <span className='preview-button-text'>
                                        Video Content Drives to Your Landing Page
                                    </span>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    );
};

export default Preview;
